<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      @resetBtnClick="handleResetForm"
      :pageInfo="pageInfo"
    >
      <template #customForm v-if="['view', 'edit'].includes(currentPageType)">
        <el-col
          :span="24"
          style="padding: 18px"
          v-for="item in currentParameterData"
          :key="item.key"
        >
          <LTable
            :ref="(el) => setTableRef(item.key, el)"
            :listDataOptions="item.value"
            :templateType="templateType"
            :appName="item.key"
            :isShowCheckItem="false"
            :isViewPage="currentPageType === 'view'"
            :paramTypesMap="paramTypesMap"
            @handleRemoveApplicationByName="handleRemoveTargetApplicationByName"
          />
        </el-col>
      </template>
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  onUnmounted,
  watch,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { findCurrentSelectedTreeNode } from '@/utils/util'
import LTable from './dynamic-table2.vue'
import { getParamsOptions } from '@/utils/common'
import { ElMessage } from 'element-plus'
import Validator from '@/validators'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'TerminalView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('general.router-terminal-view'),
    pageType: 'view'
  },
  {
    routesName: 'TerminalEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('general.router-terminal-edit'),
    pageType: 'edit',
    isResetFields: false
  },
  {
    routesName: 'TerminalNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('general.router-terminal-new'),
    pageType: 'add'
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

// 创建一个对象来存储所有的表格实例引用
const tableRefs = ref({})

const setTableRef = (key, el) => {
  if (el) {
    tableRefs.value[`tableRef${key}`] = el
  }
}
const store = useStore()

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig
const currentPageType = ref(pageType)

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})
const paramTypesMap = ref({})
// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})

currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const bus = appContext.config.globalProperties.$bus

const baseFormRef = ref('')
const initMerchantOptions = async (form) => {
  const merchantIdItem = currentPageConfig.baseFormConfig.value.formItems.find(
    (item) => item.field === 'merchantId'
  )
  const merchantOption = await store.dispatch(
    'user/getMerchantListByTenantId',
    {
      tenantId: form?.tenantId
    }
  )
  // option更新时同时清空merchant数据
  baseFormRef.value.formData.merchantId = ''
  if (form.tenantId) {
    merchantIdItem.options = merchantOption?.map((item) => {
      return {
        value: item.id,
        label: item.userName
      }
    })
  } else {
    merchantIdItem.options = []
  }
}
const initModelOption = async (form) => {
  console.log(form)
  const modelIdItem = currentPageConfig.baseFormConfig.value.formItems.find(
    (item) => item.field === 'modelId'
  )
  if (form?.tenantId === undefined || !form?.tenantId) form.tenantId = ''
  const modelOption = await store.dispatch(
    'globalParams/getTerminalModelListByTenantId',
    form?.tenantId
  )
  modelIdItem.options = modelOption?.map((item) => {
    return {
      value: item.id,
      label: item.name
    }
  })
}

const handleChange = async (form) => {
  console.log(form)
  const { fieldName } = form
  if (fieldName === 'tenantId') {
    await initMerchantOptions(form)
    await initModelOption(form)
  }
}

const listData = ref([])

const getParameterList = async (type) => {
  listData.value = await store.dispatch('param/getAllParameters', type)
}

const tenantList = getParamsOptions('tenantList')
onMounted(async () => {
  // 查询ParameterList
  await getParameterList('00')
  bus.on('linkageOptionsChange', handleChange)
})

// const parameterList = ref(null)
// const selectedItems = ref([])
// watch(
//   () => parameterList?.value?.selectedListData,
//   (newVal) => {
//     if (newVal) {
//       selectedItems.value = newVal?.map((item) => item.key)
//     }
//   }
// )

onUnmounted(() => {
  // 组件销毁前注销事件监听
  bus.off('linkageOptionsChange', handleChange)
})
const initialTableData = ref([])
// 5、定义方法获取当前页面的初始化数据
const currentParameterData = ref([])
const isExitTerminalId = ref(null)
const getCurrentPageInfo = async () => {
  const terminalInfo = await store.dispatch('terminal/handleGetTerminal', {
    id
  })
  if (terminalInfo?.parameter) {
    console.log('------------------------------------------')
    const tableData = Object.entries(JSON.parse(terminalInfo?.parameter)).map(
      ([key, value]) => {
        return {
          key,
          value
        }
      }
    )
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].value = Object.entries(tableData[i].value).map(
        ([key, value]) => {
          return {
            key,
            value
          }
        }
      )
    }

    currentParameterData.value = tableData
    initialTableData.value = tableData
    console.log(currentParameterData.value, 'currentParameterData')
  }
  const terminalIdItem = baseFormConfigRef.value?.formItems?.find(
    (item) => item.field === 'terminalId'
  )
  // 判断terminalId 是否为空 如果为空就将disabled设置为false
  paramTypesMap.value = terminalInfo?.paramTypes
  terminalIdItem.otherOptions.disabled = !!terminalInfo.terminalId
  isExitTerminalId.value = !!terminalInfo.terminalId

  // selectedItems.value = currentParameterData.value

  // 检查 terminalInfo 是否包含有效数据（非空对象）
  if (terminalInfo && Object.keys(terminalInfo).length > 0) {
    // 尝试并行化 initMerchantOptions 和 initModelOption
    const merchantPromise = initMerchantOptions(terminalInfo)
    const modelPromise = initModelOption(terminalInfo)
    await Promise.all([merchantPromise, modelPromise])

    // 在这里处理 tenantId 和其他可能的属性
    if (terminalInfo.tenantId) {
      terminalInfo.tenantId = findCurrentSelectedTreeNode(
        tenantList,
        terminalInfo.tenantId,
        'id'
      )
    }
    pageInfo.value = terminalInfo
  }
}

const handleRemoveTargetApplicationByName = (name) => {
  const target = currentParameterData.value?.find((item) => item.key === name)
  if (target) {
    currentParameterData.value.splice(
      currentParameterData.value.indexOf(target),
      1
    )
  }
}

watch(
  isExitTerminalId,
  (newVal) => {
    baseFormConfigRef.value.validateRules = {
      terminalId: [
        ...(newVal
          ? [{ required: true, message: 'Required', trigger: 'blur' }]
          : []),
        {
          min: 5,
          max: 20,
          message: {
            locale: 'general.length-warnning-message',
            range: {
              min: 5,
              max: 20
            }
          },
          trigger: 'change'
        }
      ],
      sn: [
        { validator: Validator.minLength, length: 2 },
        { validator: Validator.maxLength, length: 50 }
      ],

      batchType: [
        {
          required: true,
          message: 'general.required',
          trigger: 'change'
        }
      ],

      modelId: [
        {
          required: true,
          message: 'general.required',
          trigger: 'change'
        }
      ]
    }
  },
  { immediate: true, deep: true }
)

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  const form = { ...data }
  form.tenantId = form.tenantId
    ? findCurrentSelectedTreeNode(tenantList, form.tenantId, 'name')
    : ''

  let url = 'handleTerminalAction'
  let shouldSubmitForm = true

  if (pageType === 'edit') {
    if (!form.terminalId) {
      ElMessage.error('Terminal ID is required')
      return
    }
    const appInstanceParams = {}
    let allValidationsPassed = true
    const hasParameters =
      currentParameterData.value && currentParameterData.value.length > 0

    if (hasParameters) {
      for (const item of currentParameterData.value) {
        const refName = `tableRef${item.key}`
        const tableInstance = tableRefs.value[refName]
        if (tableInstance) {
          try {
            tableInstance.formRef.validate((valid) => {
              if (valid) {
                if (tableInstance.parameterList) {
                  const appData = {}
                  for (const paramItem of tableInstance.parameterList) {
                    appData[paramItem.key] = paramItem.value
                  }
                  appInstanceParams[item.key] = appData
                }
              } else {
                allValidationsPassed = false
              }
            })

            if (!allValidationsPassed) {
              ElMessage.error('Please check the form for errors')
              break
            }
          } catch (error) {
            allValidationsPassed = false
            console.error(error)
            break
          }
        }
      }

      form.id = id
      url = 'handleUpdateTerminal'
      form.parameter =
        allValidationsPassed && Object.keys(appInstanceParams).length > 0
          ? JSON.stringify(appInstanceParams)
          : ''

      shouldSubmitForm = allValidationsPassed
    } else {
      // currentParameterData is empty, set form.parameter to empty string
      form.parameter = ''
      // If there are no parameters, we still might want to submit the form (depending on business logic)
      // Here we assume we do want to submit in this case
      url = 'handleUpdateTerminal' // Potentially change the URL if needed for empty parameter submissions
      form.id = id // Only set the ID if we're actually going to submit the form
    }
  }

  // Submit the form if validations passed or there are no parameters to validate
  if (shouldSubmitForm) {
    handleMessageCommit(`terminal/${url}`, form).then(() => {
      router.push('/terminal/overview')
    })
  }
}

const handleResetForm = () => {
  // 遍历重置相关的表单数据
  if (
    currentParameterData.value &&
    currentParameterData.value.length > 0 &&
    pageType === 'edit'
  ) {
    for (const item of currentParameterData.value) {
      const refName = `tableRef${item.key}`
      const tableInstance = tableRefs.value[refName]
      // 调用子组件的查询参数
      nextTick(() => {
        tableInstance.clearCurrentInstanceSearch()
        tableInstance.resetInitialData()
        // currentParameterData.value = initialTableData.value
      })
    }
  }
}
</script>

<style scoped></style>
