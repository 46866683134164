import {
  getCommonParamsOptions,
  getParamsOptionsAsync,
  getGlobalParamsOptionsAsync
} from '@/utils/common.js'

// import Validator from '@/validators'
// import { isIP } from '@/utils/validate'

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'terminalId',
      type: 'input',
      label: 'terminal.id',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn',
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'host',
    //   type: 'input',
    //   label: 'template.host'
    // },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant-id',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getParamsOptionsAsync('tenantList'),
      isLinkageOptions: true,
      otherOptions: {
        isSingle: true
      }
    },
    {
      field: 'merchantId',
      type: 'select',
      label: 'merchant.merchant-name',
      options: []
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        })
    },
    {
      field: 'batchType',
      type: 'select',
      label: 'param.batch',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('batch_type')
    },

    {
      field: 'remark',
      type: 'textarea',
      label: 'general.remark',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {}
}
