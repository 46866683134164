import {
  getCommonParamsOptions,
  getGlobalParamsOptionsAsync,
  getParamsOptionsAsync
} from '@/utils/common.js'

export const baseViewFormConfig = {
  formItems: [
    {
      field: 'terminalId',
      type: 'input',
      label: 'terminal.id',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn',
      otherOptions: {
        disabled: true
      }
    },

    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant-id',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getParamsOptionsAsync('tenantList'),
      isLinkageOptions: true,

      otherOptions: {
        isSingle: true,
        disabled: true
      }
    },
    {
      field: 'merchantId',
      type: 'select',
      label: 'merchant.merchant-name',
      options: [],
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        }),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'batchType',
      type: 'select',
      label: 'param.batch',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('batch_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'remark',
      type: 'textarea',
      label: 'general.remark',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        disabled: true
      }
    }
  ]
}
