<template>
  <div class="tb-container2" ref="tbContainerRef">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <h6
        style="color: #6c717a; font-size: 15px; font-family: 'Helvetica Neue'"
      >
        {{ props.appName }}
        <el-tooltip
          v-if="!isViewPage"
          class="item"
          effect="light"
          content="Remove this Application ?"
          placement="top"
        >
          <i
            style="color: #e63b3b; cursor: pointer; margin-left: 6px"
            class="el-icon-delete"
            @click="removeApplication(props.appName)"
          ></i>
        </el-tooltip>
      </h6>
      <!-- 条件渲染的 el-input -->
      <el-input
        v-if="!isViewPage"
        v-model="inputValue"
        class="w-50 m-2"
        placeholder="Search Parameter"
        style="width: 20%"
        ><i><Search /></i
      ></el-input>
    </div>
    <!-- 表格 -->
    <el-table
      ref="tableRef"
      :data="displayList"
      style="width: 100%; margin-top: 5px"
      @selection-change="handleSelectionChange"
      :max-height="maxHeight"
      border
      stripe
    >
      <el-table-column
        v-if="parameterList.length > 0"
        type="index"
        label="Seq"
        :width="50"
      />
      <!-- <el-table-column prop="id" label="ID" width="100" /> -->
      <!-- TemplateType Template -->

      <el-table-column prop="key" label="Parameter" width="450" />

      <el-table-column prop="length" label="Value Length" width="160">
        <template #default="{ row }">
          {{
            typeof row?.value === 'string'
              ? row?.value?.length
              : String(row?.value).length
          }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Parameter Type" width="160">
        <template #default="{ row }">
          {{ paramTypesMap[row?.key] ? map[paramTypesMap[row?.key]] : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Value">
        <template #default="{ row }">
          <!-- 区分templateType 区分类型 -->
          <!--字符串 String-->

          <!-- 字符串转成数字类型 -->
          <div v-if="!row.isEditable">
            <span>{{ row?.value }}</span>
            <i
              v-if="!isViewPage"
              style="color: #1a6eb6; cursor: pointer"
              class="el-icon-edit-outline"
              @click="handleEdit(row)"
            ></i>
          </div>
          <el-form :model="row" ref="formRef" inline-message v-if="!isViewPage">
            <el-form-item
              prop="value"
              :rules="getCurrentRule(paramTypesMap[row?.key])"
            >
              <el-input
                style="width: 65%"
                ref="inputRef"
                v-show="row.isEditable"
                v-model="row.value"
                @blur="handleInputBlur(row)"
                :disabled="row.disabled"
                maxlength="255"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <!--Boolean-->
        </template>
      </el-table-column>

      <!-- Card -->
    </el-table>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  defineExpose,
  nextTick,
  watch,
  computed,
  defineEmits
} from 'vue'
// import { useStore } from 'vuex'
import { Search } from '@element-plus/icons'
import { useCommonParam } from '@/hooks/use-common-params.js'

const props = defineProps({
  templateType: {
    type: String,
    default: '00' // Parameter
  },
  listDataOptions: {
    type: Array,
    default: () => []
  },
  isViewPage: {
    type: Boolean,
    default: false
  },
  isShowCheckItem: {
    type: Boolean,
    default: true
  },
  maxHeight: {
    type: Number,
    default: 800
  },
  appName: {
    type: String,
    default: ''
  },
  paramTypesMap: {
    type: Object,
    default: () => {}
  }
})
const map = ref({
  '00': '00 - String',
  '01': '01 - Integer',
  '02': '02 - Boolean'
})
const emit = defineEmits(['handleRemoveApplicationByName'])

const removeApplication = (name) => {
  emit('handleRemoveApplicationByName', name)
}
// 用于跟踪当前正在编辑的行的ID
const currentEditingId = ref(null)
const tableRef = ref(null)
// const store = useStore()
const parameterList = ref([])
const selectedRowItems = ref([])

const displayList = computed(() => {
  console.log(parameterList.value)
  return parameterList.value.filter(
    (data) =>
      !inputValue.value ||
      data.key.includes(inputValue.value) ||
      data.key.toLowerCase().includes(inputValue.value.toLowerCase())
  )
})
// const selectedRowKeys = ref([])
const getCurrentRule = (type) => {
  if (type === '01') {
    return [
      {
        required: true,
        message: 'Please input an integer value',
        trigger: 'blur',
        // 假设你的校验库支持这种类型检查
        pattern: /^[-+]?\d+$/ // 正则表达式，用于匹配整数
      }
    ]
  } else if (type === '02') {
    return [
      {
        pattern: /^(true|false)$/,
        message: 'Please input true or false',
        trigger: 'blur'
      }
    ]
  } else {
    return [
      {
        required: true,
        message: 'Required',
        trigger: 'blur'
        // // 假设你的校验库支持这种类型检查
        // pattern: /^[-+]?\d+$/ // 正则表达式，用于匹配整数
      }
    ]
  }
}

const inputRef = ref(null)
const handleEdit = (row) => {
  // 判断当前正在编辑的行 是否为当前点击的行
  if (currentEditingId.value !== null && currentEditingId.value !== row?.key) {
    // 遍历parameterList 数组 关闭当前的编辑状态
    parameterList.value = parameterList.value.map((item) => {
      if (item.key === currentEditingId.value) {
        item.isEditable = false
        currentEditingId.value = null
      }
      return item
    })
  }
  parameterList.value = parameterList.value.map((item) => {
    if (item.key === row.key) {
      item.isEditable = true
      currentEditingId.value = item.key
    }
    return item
  })
  nextTick(() => {
    inputRef.value.focus()
  })
}

const handleInputBlur = (row) => {
  parameterList.value = parameterList.value.map((item) => {
    if (item.key === row.key) {
      item.isEditable = false
      currentEditingId.value = null
    }
    return item
  })
}

const formRef = ref(null)
const validResult = ref(false)
const getValidateRes = () => {
  console.log('111111111111111111111')
  formRef.value.validate((valid) => {
    validResult.value = valid
    return valid
  })
}

const handleSelectionChange = (val) => {
  selectedRowItems.value = val
  console.log(val)
  // 重置选中状态

  listData.value = listData.value.map((item) => {
    for (let i = 0; i < val.length; i++) {
      // 重置选中状态
      if (item?.checked) item.checked = false
      if (item.key === val[i].key) {
        return {
          ...item,
          checked: true
        }
      }
    }
    return item
  })
}

watch(
  () => selectedRowItems.value.length,
  (newVal, oldVal) => {
    if (newVal === 0 && oldVal > 0) {
      listData.value.forEach((item) => {
        if (item.checked) {
          item.checked = false
        }
      })
    }
  }
)

const inputValue = ref('')
watch(
  () => inputValue.value,
  async (newVal, oldVal) => {
    if (newVal === '' && oldVal !== '') {
      // 保存当前选中的行
      const checkedItems = listData.value
        ?.filter((item) => item.checked)
        ?.map((item) => item.key)
      console.log(checkedItems)
      nextTick(() => {
        for (let i = 0; i < parameterList.value.length; i++) {
          if (checkedItems.includes(parameterList.value[i].key)) {
            tableRef.value.toggleRowSelection(parameterList.value[i], true)
          }
        }
      })
    }
  }
)
const initialParameterData = ref([])
watch(
  () => props.listDataOptions,
  (newVal) => {
    if (newVal) {
      console.log(newVal, '------------------------------')
      parameterList.value = newVal
      initialParameterData.value = newVal
    }
  }
)

const listData = ref([])
const valueTypes = ref([])
const valueTypeMap = ref({})
const cardTypeMap = ref({})
const cardTypes = ref([])

const resetInitialData = () => {
  initialListData()
}

const initialListData = async () => {
  parameterList.value = props.listDataOptions
  console.log(parameterList.value)
  parameterList.value = parameterList.value.map((item) => ({
    ...item,
    isEditable: false
  }))
  listData.value = JSON.parse(JSON.stringify(parameterList.value))
}
onMounted(async () => {
  initialListData(props.templateType)
  // loadCheckedItems()
  valueTypes.value = await useCommonParam('value_type')
  valueTypes.value.forEach((item) => {
    valueTypeMap.value[item.value] = item.key
  })

  cardTypes.value = await useCommonParam('card_type')
  cardTypes.value.forEach((item) => {
    cardTypeMap.value[item.value] = item.key
  })
})

watch(
  () => props.templateType,
  async (newVal) => {
    if (newVal) {
      await initialListData(newVal)
      // loadCheckedItems()
    }
  }
)
const selectedListData = ref([])
watch(
  () => listData.value,
  (newVal) => {
    if (newVal) {
      console.log(newVal)
      selectedListData.value = newVal.filter((item) => {
        if (item.checked) {
          return item.key
        }
      })
      console.log(selectedListData.value)
    }
  },
  { deep: true }
)

const clearCurrentInstanceSearch = () => {
  inputValue.value = ''
}

defineExpose({
  selectedRowItems,
  getValidateRes,
  selectedListData,
  parameterList,
  validResult,
  formRef,
  clearCurrentInstanceSearch,
  resetInitialData
})
</script>

<style>
/* .tb-container2 .el-table .el-table__cell {
  padding: 0 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
} */
</style>
